$(function(){
	$(document).on("click",function(e){
		var ele = $(e.target);

		if(ele.closest("#menu-toggle").length){
		e.preventDefault();
			console.log("opend menu");
			$(".sidebar").addClass("opened");
			$("body").addClass("nav-open");
		}
		if(ele.closest("#menu-close").length){
		e.preventDefault();
			console.log("close menu");
			$(".sidebar").removeClass("opened");
			$("body").removeClass("nav-open");
		}
	});
	$(document).mouseup(function(e){
    var container = $("#main-navigation.opened");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
        container.removeClass("opened");
        $("body").removeClass("nav-open")
    }
});
});